import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router : Router, public toastr:ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    let tokenizedReq = req.clone({
      // setHeaders : {
      //   'token' : localStorage.getItem('token')
      // }
    });
    return next.handle(tokenizedReq).pipe(tap(res=>{},
      (err:any)=>{
        if (err instanceof HttpErrorResponse && err.status === 401){
          this.router.navigate(['/login']);
          localStorage.clear();
          this.toastr.error(err.error.error)
        }
      }
    ));
  }
}
