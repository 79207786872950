import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FooterRoutingModule } from './footer-routing.module';
import { BfpTranslateModule } from '../bfp-translate/bfp-translate.module';



@NgModule({
  declarations: [FooterComponent],
  imports: [
    FooterRoutingModule,
    CommonModule,
    BfpTranslateModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule { }
