<footer class="footer-section">
    <div class="footer-top">
        <div class="px-3">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 mb-4 mb-lg-0">
                    <div class="widget company-intro-widget">
                        <a class="site-logo" href="https://innerdevelopmentgoals.org/" target="_blank"><img src="assets/logos/IDG_Logo_White_Hori.png" alt="logo"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">ABOUT</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://youtu.be/xsB5ci-rgGg">Why IDGs</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/measure-inner-development-goals/">Why measurement</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">GET INVOLVED</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://idg-global-practitioners-network.in.howspace.com/welcome">Global Practitioner Network</a></li>
                            <li><a target="_blank" href="https://innerdevelopmentgoals.org/get-involved/hubsnetworks/">Hubs & Networks</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">FRAMEWORK</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://drive.google.com/file/d/1s_TQbFreKH13kruxss8aQsbWlxKVFsfK/edit">Scientific Foundations</a></li>
                            <li><a target="_blank" href="https://transitionmakers.nl/">Toolkit</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">PARTNERS</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://academy.beingatfullpotential.com/">Academy</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com">Being at Full Potential</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
            <div class="px-3">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12 text-lg-right text-center text-lg-right">
                        <div class="copy-right-text">Powered by <a href="https://beingatfullpotential.com/" target="_blank">Being at Full Potential</a></div>
                    </div>
                </div>
            </div>
        </div>
</footer>
