<footer class="footer-section">
    <div class="footer-top">
        <div class="px-3">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 mb-4 mb-lg-0">
                    <div class="widget company-intro-widget">
                        <a class="site-logo" href="https://innerdevelopmentgoals.org/" target="_blank"><img [src]="'IDG_FOOTER.LOGO' | translate" alt="logo"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">{{'IDG_FOOTER.ABOUT' | translate}}</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://youtu.be/xsB5ci-rgGg">{{'IDG_FOOTER.WHY_IDGS' | translate}}</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/measure-inner-development-goals/">{{'IDG_FOOTER.WHY_MEASUREMENT' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">{{'IDG_FOOTER.GET_INVOLVED' | translate}}</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://idg-global-practitioners-network.in.howspace.com/welcome">{{'IDG_FOOTER.GLOBAL_PRACTITIONER_NETWORK' | translate}}</a></li>
                            <li><a target="_blank" href="https://innerdevelopmentgoals.org/get-involved/hubsnetworks/">{{'IDG_FOOTER.HUBS_AND_NETWORKS' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">{{'IDG_FOOTER.FRAMEWORK' | translate}}</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://drive.google.com/file/d/1s_TQbFreKH13kruxss8aQsbWlxKVFsfK/edit">{{'IDG_FOOTER.SCIENTIFIC_FOUNDATIONS' | translate}}</a></li>
                            <li><a target="_blank" href="https://transitionmakers.nl/">{{'IDG_FOOTER.TOOLKIT' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-lg-0">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">{{'IDG_FOOTER.PARTNERS' | translate}}</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://academy.beingatfullpotential.com/">{{'IDG_FOOTER.ACADEMY' | translate}}</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com">{{'IDG_FOOTER.BEING_AT_FULL_POTENTIAL' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
            <div class="px-3">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12 text-lg-right text-center text-lg-right">
                        <div class="copy-right-text">{{'IDG_FOOTER.POWERED_BY' | translate}} <a href="https://beingatfullpotential.com/" target="_blank">Being at Full Potential</a></div>
                    </div>
                </div>
            </div>
        </div>
</footer>
