import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    public toastr: ToastrService,
    private translate: TranslateService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>>{
    let tokenizedReq = req.clone({
      // setHeaders : {
      //   'token' : localStorage.getItem('token')
      // }
    });
    return next.handle(tokenizedReq).pipe(
      map((res: any) => {
        console.log(res);
        if (res?.body?.message) {
          if (res?.body?.message === 'Successfully Logged in!') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.LOGIN_SUCCESS');
          }
          if (res?.body?.message === 'success') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.SUCCESS');
          }
          if (res?.body?.message === 'The email has already been taken.') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.EMAIL_ALREADY_TAKEN');
          }
          if (res?.body?.message === 'Invalid Login Credentials') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.INVALID_LOGIN_CREDENTIALS');
          }
          if (res?.body?.message === 'successfully added your message') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.ADDED_MSG_SUCCESS');
          }
          if (res?.body?.message === 'Invalid email address') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.INVALID_EMAIL_ADDRESS');
          }
          if (res?.body?.message === 'password sent your valid email address') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.PASS_RESET_MAIL_SENT');
          }
          if (res?.body?.message === 'password has reset') {
            res.body.message = this.translate.instant('IDG_TOAST_MSG.PASS_HAS_RESET');
          }

        }
        return res;
      }),
      tap(
      (res: any)=>{},
      (err:any)=>{
        if (err instanceof HttpErrorResponse && err.status === 401){
          this.router.navigate(['/login']);
          localStorage.clear();
          this.toastr.error(err.error.error)
        }
      }
    ));
  }
}
