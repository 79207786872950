import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BfpTranslateService {
  constructor(private translate: TranslateService) {}

  init() {
    this.translate.addLangs(['en', 'pt']);
    this.translate.setDefaultLang('en');
    const savedLang = localStorage.getItem('lang') || '';
    const browserLang = this.translate.getBrowserLang();
    const lang = savedLang || browserLang;
    this.translate.use(lang.match(/en|pt/) ? lang : 'en');
  }

  instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translate.instant(key, interpolateParams);
  }

  changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translate.use(lang.match(/en|pt/) ? lang : 'en');
  }

  getLang(): string {
    const lang = localStorage.getItem('lang') || '';
    return lang.match(/en|pt/) ? lang : 'en';
  }
}
