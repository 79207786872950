import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Event } from '@angular/router';
import { BfpTranslateService } from './bfp-translate/bfp-translate.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'human-potential';
  loading: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private bfpTranslate: BfpTranslateService
  ) {
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.loading = true;
      }
      if (routerEvent instanceof NavigationEnd) {
        this.loading = false;
        window.scrollTo(0, 0);
      }
    });
    this.bfpTranslate.init();
    this.translate.addLangs(['en', 'pt']);
    this.translate.setDefaultLang('en');
    const savedLang = localStorage.getItem('lang');
    const browserLang = this.translate.getBrowserLang();
    const lang = savedLang || browserLang;
    this.translate.use(lang.match(/en|pt/) ? lang : 'en');
  }
}
