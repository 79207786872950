import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLangComponent } from './change-lang/change-lang.component';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [ChangeLangComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
  ],
  exports: [
    ChangeLangComponent,
    TranslateModule
  ],
})
export class BfpTranslateModule {}
