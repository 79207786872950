import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FooterRoutingModule } from './footer-routing.module';



@NgModule({
  declarations: [FooterComponent],
  imports: [
    FooterRoutingModule,
    CommonModule
  ],
  exports: [FooterComponent],
})
export class FooterModule { }
