import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BfpTranslateService } from '../bfp-translate.service';

@Component({
  selector: 'change-lang',
  templateUrl: './change-lang.component.html',
  styleUrls: ['./change-lang.component.scss'],
  styles: [
    `
      :host: {
        width: 100%;
      }
    `,
  ],
})
export class ChangeLangComponent {
  public selectedLang: string;

  constructor(private translate: TranslateService, private bfpTranslateService: BfpTranslateService) {
    this.translate.addLangs(['en', 'pt']);
    this.translate.setDefaultLang('en');
    const savedLang = localStorage.getItem('lang');
    const browserLang = this.translate.getBrowserLang();
    const lang = savedLang || browserLang;
    this.translate.use(lang.match(/en|pt/) ? lang : 'en');
    this.selectedLang = this.bfpTranslateService.getLang();
  }

  changeLanguage(lang: string) {
    this.bfpTranslateService.changeLang(lang);
  }
}
