import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ApiTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  // Load translations from API
  getTranslation(lang: string): Observable<any> {
    return this.http.get(`/assets/i18n/${lang}.json`);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new ApiTranslateLoader(http); // Replace with your API URL
}

