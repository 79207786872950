<div *ngIf="loading" class="common_loading">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="app-layout">
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>